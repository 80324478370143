import {ComponentItem} from "../utilities/ContentBuilder";


interface NavigationProps {
    layout?: ComponentItem[],
    key?: string
}

const filterNavigation = ({layout = [], key = "sectionTitle"}: NavigationProps) => {
    return layout.filter(item => item.component === key).map(item => item.settings.title);
}

export default filterNavigation;
