import React, { CSSProperties } from "react";
import { Hero, HeroOverlay } from "../styles/Styles";
import { API } from "../config/config";

interface HeroImageProps {
  backgroundColor?: string;
  image: {
    path: string;
  };
  imageMobile: {
    path: string;
  };
  imageStyle?: string;
  title: string;
  backgroundStyle: string;
}
const defaultImageStyle: CSSProperties = {
  width: "100%",
  height: "100%",
};
export const HeroImage: React.FC<HeroImageProps> = ({
  image,
}) => {
  return (
    <HeroOverlay>
        <Hero
          backgroundUrl={API.assetUrl + image.path}
          style={defaultImageStyle}
        />
    </HeroOverlay>
  );
};
