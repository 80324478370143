import React from "react";
import styled from "styled-components";
import { Container } from "../styles/Styles";
import { API } from "../config/config";
import useFetch from "../hooks/useFetch";
import { device } from "../utilities/Breakpoint";
import { Accordion } from "./subComponents/Accordion";

export interface ListImageProps {
  _id: string;
  img: {
    path: string;
  };
  title: string;
  description: string;
  fullwidth: boolean;
  attributes: value[];
}

interface FullWidthProps {
  fullWidth: boolean;
}

interface value {
  value: attributes;
}

interface attributes {
  text: string;
  key: string;
  is_subtitle: boolean;
}

interface TeamListProps {
  collectionName: string;
  backgroundStyle: string;
}

export const ImageList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;

  @media ${device.laptop} {
    justify-content: flex-start;
  }
  align-items: flex-start;
`;

export const ListImage = styled.div<FullWidthProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
  padding: 0 2rem 0 0;
  flex-basis: 100%;
  @media ${device.laptopM} {
    flex-basis: ${({ fullWidth }) => (fullWidth ? "100%" : "48%")};
    align-items: ${({ fullWidth }) => (fullWidth ? "flex-start" : "center")};
  }
`;

const ListContainer = styled.div`
  max-width: 1024px;
`;

const ImageDescription = styled.div<FullWidthProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  /* border-top: 1px solid; */
  padding-left: 0;
  max-width: 49rem;
  width: 100%;
  flex: 1 0;
  @media ${device.laptop} {
    align-items: ${({ fullWidth }) => (fullWidth ? "flex-start" : "center")};
  }
`;

const ImageStyling = styled.img`
  max-height: 36rem;
  height: 36rem;
  width: 100%;
  max-width: 49rem;
  object-fit: cover;
  flex: 1 0;
  box-shadow: 1px 2px 2px hsl(0deg 0% 77%), 2px 4px 4px hsl(0deg 0% 77%),
    3px 6px 6px hsl(0deg 0% 77%);
`;

export const TeamPosition = styled.h3`
  font-size: 1.8rem;
  margin: 0rem 0;
  max-width: 24rem;
  text-align: center;
`;

export const TeamList: React.FC<TeamListProps> = ({
  collectionName,
  backgroundStyle,
}) => {
  const [content] = useFetch(
    `${API.baseUrl}api/collections/get/${collectionName}?token=${API.key}`,
    { entries: [] }
  ) as any;

  return (
    <Container backgroundStyle={backgroundStyle}>
      <ListContainer>
        <ImageList>
          {content.entries.map((tile: ListImageProps) => (
            <ListImage key={tile._id} fullWidth={tile.fullwidth}>
              <ImageStyling
                src={API.assetUrl + tile.img.path}
                alt={tile.title}
              />
              <ImageDescription fullWidth={tile.fullwidth}>
                <Accordion
                  title={tile.title}
                  subTitle={tile.description}
                  attributes={tile.attributes}
                  navIcon={true}
                  isName={true}
                />
              </ImageDescription>
            </ListImage>
          ))}
        </ImageList>
      </ListContainer>
    </Container>
  );
};
