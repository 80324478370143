import React from "react";
import { H2, SectionTitle, TitleContainer } from "../styles/Styles";
import styled from "styled-components";

interface NavSectionTitleProps {
  title: string;
  backgroundStyle: string;
  padding?: string;
}

const AnchorLink = styled.a`
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
`;

export const NavSectionTitle: React.FC<NavSectionTitleProps> = ({
  title,
  backgroundStyle,
  padding,
}) => {
  return (
    <TitleContainer backgroundStyle={backgroundStyle} padding={padding}>
      <AnchorLink className="anchor" id={title}></AnchorLink>
      <SectionTitle>
        <H2>{title}</H2>
      </SectionTitle>
    </TitleContainer>
  );
};
