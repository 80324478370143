export const DEVICE_SIZE = {
    tablet: 768,
    laptop: 1024,
    laptopM: 1200
}


export const smallerThenDeviceSize = (device: any) => document.documentElement.clientWidth < device;

const size = {
    mobileS: "320px",
    mobileM: "375px",
    mobileL: "425px",
    tablet: DEVICE_SIZE.tablet + "px",
    laptop: DEVICE_SIZE.laptop + "px",
    laptopM: DEVICE_SIZE.laptopM + "px",
    laptopL: "1440px",
    desktop: "2560px"
};

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopM: `(min-width: ${size.laptopM})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`
};


