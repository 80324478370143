import React from "react";
import {
  ContactContainer,
  ContactSection,
  Container,
  H3,
  MapFull,
  P,
  ConfigLink,
  MapContact,
} from "../styles/Styles";
import "../styles/map.css";
import { MapContainer } from "react-leaflet";
import GoogleMapReact from "google-map-react";
import Icon from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";
import styled from "styled-components";

const OpeningHour = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
`;
const OpeningHourElement = styled.div`
  margin-right: 2rem;
`;

interface ContactProps {
  backgroundStyle: string;
  content: textType[];
  openingHourTitle: string;
  openingHourContent: openingHourType[];
}

type openingHourType = {
  value: {
    day: string;
    time: string;
  };
};
type textType = {
  value: {
    title: string;
    content: string;
    content_line2: string;
    content_line3: string;
    content_line4: string;
    is_phone: boolean;
    is_email: boolean;
    key: string;
  };
};

type State = {
  lat: number;
  lng: number;
  zoom: number;
};

const defaultZoom = 14;
const practiceLocation: State = {
  lat: 47.36555176689763,
  lng: 8.547753234146382,
  zoom: defaultZoom,
};
const mapCenter: State = {
  lat: 47.36555176689763,
  lng: 8.547753234146382,
  zoom: defaultZoom,
};
//@ts-ignore
const LocationPin = ({ lat, lng, text }) => (
  <div className="pin">
    <Icon icon={locationIcon} className="pin-icon" />
    <p className="pin-text">{text}</p>
  </div>
);

const createMapOptions = () => {
  let mapOptions = {
    center: { lat: 47.36555176689763, lng: 8.547753234146382 },
    clickableIcons: true,
    disableDoubleClickZoom: false,
    draggable: true,
    fullscreenControl: true,
    gestureHandling: "cooperative",
    keyboardShortcuts: true,
    mapTypeControl: false,
    mapTypeControlOptions: {
      text: "Default (depends on viewport size etc.)",
      style: 0,
    },
    mapTypeId: "roadmap",
    rotateControl: true,
    scaleControl: true,
    streetViewControl: false,
    styles: [
      {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [{ color: "#444444" }],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [{ color: "#f2f2f2" }],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [{ saturation: -100 }, { lightness: 45 }],
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [{ visibility: "simplified" }],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [{ color: "#4f595d" }, { visibility: "on" }],
      },
    ],
    zoom: 15,
    zoomControl: true,
  };
  // let mapElement = document.getElementById('ez-map');
  // let map = new google.maps.Map(mapElement, mapOptions);
  // let heatmap = new google.maps.visualization.HeatmapLayer({data: []});
  // heatmap.setOptions({"dissipating": false, "opacity": 0.6, "radius": 2});
  // heatmap.setMap(map);
  // google.maps.event.addDomListener(window, "resize", function () {
  //     var center = map.getCenter();
  //     google.maps.event.trigger(map, "resize");
  //     map.setCenter(center);
  // });
  return mapOptions;
};
export const Contact: React.FC<ContactProps> = ({
  backgroundStyle,
  content,
  openingHourTitle,
  openingHourContent,
}) => {
  const setTextContent = (textLeft: textType[]) => {
    return textLeft.map((item: textType, index: number) => (
      <div key={item.value.title + index}>
        <H3>{item.value.title}</H3>
        <P noIndent={true}>
          {item.value.is_phone || item.value.is_email ? (
            <ConfigLink
              key={item.value.content}
              hasHover={true}
              href={
                item.value.is_phone ? "tel: " : "mailto: " + item.value.content
              }
            >
              {item.value.content}
            </ConfigLink>
          ) : (
            item.value.content
          )}
        </P>
        {!!item.value.content_line2 ? (
          <P noIndent={true}>{item.value.content_line2}</P>
        ) : (
          ""
        )}
        {!!item.value.content_line3 ? (
          <P noIndent={true}>{item.value.content_line3}</P>
        ) : (
          ""
        )}
        {!!item.value.content_line4 ? (
          <P noIndent={true}>{item.value.content_line4}</P>
        ) : (
          ""
        )}
      </div>
    ));
  };
  const setOpeningHourContent = (
    openingHourTitle: string,
    openingHourContent: openingHourType[]
  ) => {
    return (
      <div>
        <H3>{openingHourTitle}</H3>

        {openingHourContent.map(
          (openingHour: openingHourType, index: number) => (
            <OpeningHour>
              <OpeningHourElement>{openingHour.value.day}</OpeningHourElement>
              <OpeningHourElement>{openingHour.value.time}</OpeningHourElement>
            </OpeningHour>
          )
        )}
      </div>
    );
  };

  return (
    <Container
      backgroundStyle={backgroundStyle}
      position={"relative"}
      fullWidth={true}
    >
      <MapContact>
        <MapFull>
          <MapContainer
            center={[47.36555176689763, 8.547753234146382]}
            zoom={14}
            zoomControl={false}
          >
            <GoogleMapReact
              defaultCenter={{ lat: mapCenter.lat, lng: mapCenter.lng }}
              defaultZoom={mapCenter.zoom}
              options={createMapOptions}
              bootstrapURLKeys={{
                key: "AIzaSyAbOHZaoybH89qLbMsg634c1XpcyAW73Lo",
              }}
            >
              <LocationPin
                lat={practiceLocation.lat}
                lng={practiceLocation.lng}
                text={"Zahnarztpraxis Stadelhofen"}
              />
            </GoogleMapReact>
          </MapContainer>
        </MapFull>
        <ContactContainer>
          <ContactSection>
            {setTextContent(content)}
            {setOpeningHourContent(openingHourTitle, openingHourContent)}
          </ContactSection>
        </ContactContainer>
      </MapContact>
    </Container>
  );
};
