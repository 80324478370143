export const StyleConfiguration: any = {
    "default": {
        backgroundColor: "#FFFFFF",
        color: "#333333",
        hover: "#333333"
    },

    "primary": {
        backgroundColor: "rgba(0,80,20,0.7)",
        color: "#ffffff",
        hover: "#000000"
    },

    "secondary": {
        backgroundColor: "rgba(0,80,20,0.7)",
        color: "#ffffff",
        hover: "#000000"
    },

    "tertiary": {
        backgroundColor: "rgba(0,80,20,0.7)",
        color: "#ffffff",
        hover: "#000000"
    },
    "transparent": {
        backgroundColor: "transparent",
        color: "#ffffff",
        hover: "#000000"
    },
}
