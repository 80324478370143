import React from "react";
import Navigator from "../utilities/Navigator";
import {MainContainer} from "../styles/Styles";
import {BrowserRouter as Router, Route} from "react-router-dom";



const ScreenCoordinator = () => {

    return (
        <Router>
            <MainContainer className={"maincontainer"}>
                <Route path="/" exact component={Navigator}/>
                <Route path="/:id" exact component={Navigator}/>
            </MainContainer>
        </Router>
    )
}

export default ScreenCoordinator
