import React from 'react';
import './App.css';
import ScreenCoordinator from "./screen/ScreenCoordinator";

function App() {
  return (
      <ScreenCoordinator />
  );
}

export default App;
