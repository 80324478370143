import React from "react";
import { Container, H3, ConfigLink, TextListContainer } from "../styles/Styles";
import styled from "styled-components";

type textType = {
  value: {
    title: string;
    is_bold: boolean;
    is_link: boolean;
    link_url: string;
  };
};

interface TextSectionProps {
  backgroundStyle: string;
  text: textType[];
  html?: string;
}

type BoldType = {
  bold: boolean;
};

const WhiteH3 = styled(H3)<BoldType>`
  font-weight: ${(props) => (props.bold ? 600 : 400)};
  align-self: flex-start;
  margin: ${(props) => (props.bold ? "1rem 0 0 0" : "0")};
`;
const PaddingTopText = styled.div``;

export const TextSection: React.FC<TextSectionProps> = ({
  text,
  backgroundStyle,
  html,
}) => {
  return (
    <Container backgroundStyle={backgroundStyle}>
      <TextListContainer>
        <PaddingTopText>
          {text
            ? text.map((item) => (
                <WhiteH3 bold={item.value.is_bold} key={item.value.title}>
                  {item.value.is_link ? (
                    <ConfigLink
                      key={item.value.title}
                      hasHover={true}
                      href={item.value.link_url}
                      target="_blank"
                    >
                      {item.value.title}
                    </ConfigLink>
                  ) : (
                    item.value.title
                  )}
                </WhiteH3>
              ))
            : ""}
          {html ? (
            <WhiteH3 bold={false}>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </WhiteH3>
          ) : (
            ""
          )}
        </PaddingTopText>
      </TextListContainer>
    </Container>
  );
};
