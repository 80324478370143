import React from "react";
import styled from "styled-components";
import { device } from "../utilities/Breakpoint";
import MobileNavigationBreadcrumb from "./MobileNavigation";
import { Logo } from "../styles/Styles";
import NavigationLink from "./NavigationLink";
import { StyleConfiguration } from "../utilities/StyleConfig";

type headerTypes = {
  backgroundColor: string;
};

type listVerticalProps = {
  listItemColor: string;
};

type listItemProps = {
  listItemHoverColor: string;
};
type headerTextConfig = {
  uppercase?: boolean;
};

const HeaderText = styled.h2<headerTextConfig>`
  font-weight: 300;
  font-size: 1.1rem;
  text-transform: ${(props) => (props.uppercase ? "uppercase" : "initial")};
  margin: 0 0 0 1.5rem;

  @media ${device.tablet} {
    font-size: 1.7rem;
  }
`;

const BackgroundColor = styled.div<headerTypes>`
  position: relative;
  background-color: ${(props) => props.backgroundColor};
  height: 5.8rem;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 6rem;
    background-color: rgb(242, 242, 242);
  }
  @media ${device.tablet} {
    height: 6rem;
  }
`;

const ListVertical = styled.ul<listVerticalProps>`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: ${(props) => props.listItemColor};
  direction: rtl;
  height: 100%;
`;

const ListItemDefault = styled.li`
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 2.5rem 1.5rem;

  @media ${device.tablet} {
    padding: 1.5rem 0.8rem;
  }
`;

const ListItem = styled(ListItemDefault)<listItemProps>`
  text-decoration: none;
  transition: all 250ms ease-in;

  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
    color: ${(props) => props.listItemHoverColor};
  }
`;

const NavigationContainer = styled.div`
  position: fixed;
  background-color: white;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 140;
  box-shadow: 0px 0px 10px #999999;
  height: 8rem;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const NavLinkContainer = styled.span`
  margin: 2rem 4rem 1rem 0;
`;
const UnstyledLink = styled.a<any>`
  color: inherit;
  text-decoration: inherit;
  transform: scale(1);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0 4rem 0.4rem 0;
  height: ${(props) => (props.open ? "auto" : "0")};

  @media screen and ${device.laptop} {
    margin: 0;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  @media screen and ${device.laptopM} {
    font-size: 1.7rem;
  }
`;

const DesktopNavigation = styled.div`
  display: none;

  @media ${device.laptop} {
    display: flex;
    align-items: center;
    padding-right: 1rem;
  }
`;
const MobileEntriesWithPadding = styled.p<any>`
  position: absolute;
  border-top: 1px solid #cdcdcd;
  border-bottom: ${(props) => (props.open ? "1px solid #cdcdcd;" : "none")};
  top: calc(8rem + 0.3rem);
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.open ? "1rem 0 1rem 0" : "0")};
  margin: 0;
  line-height: 1.4em;
  font-size: 14px;
  color: black;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  align-items: flex-end;
  justify-content: center;
  width: 100vw;
  z-index: 150;
  height: ${(props) => (props.open ? "auto" : "0")};
  opacity: ${(props) => (props.open ? "100%" : "0")};
  margin-top: ${(props) => (props.open ? "-0.3rem" : "0")};
  overflow: hidden;
  transition: all 250ms ease-in-out;

  @media ${device.laptop} {
    display: none;
  }
`;

const SpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Spacer = styled.div`
  flex: 1 1;
`;

export interface HeaderProps {
  /**
   * Optional BackgroundColor
   */
  backgroundColor?: string;
  /**
   * Optional Mode
   */
  mode?: string;
  /**
   * Navigation Items
   */
  navigationItems: string[];
  /**
   * Color of the List Items
   */
  listItemColor?: string;
  /**
   * Color of the List Items
   */
  listItemHoverColor?: string;
  /**
   * Website uris
   */
  websiteUri: string[];
}

export const Header: React.FC<HeaderProps> = ({
  backgroundColor = "white",
  navigationItems = [],
  listItemColor = "white",
  listItemHoverColor = "rgba(0, 80, 30, 0.7)",
  websiteUri = ["de", "en"],
}) => {
  const buildDesktopNavigation = () => (
    <DesktopNavigation>
      <ListVertical listItemColor={listItemColor}>
        {navigationItems.map((item: string) => (
          <ListItem listItemHoverColor={listItemHoverColor} key={item}>
            <UnstyledLink href={"#" + item}>{item}</UnstyledLink>
          </ListItem>
        ))}
        <ListItemDefault key={"testestsdfasdfnew"}>
          {websiteUri.map((item: string, index: number) => (
            <NavigationLink
              key={item}
              styling={Object.keys(StyleConfiguration)[(index % 3) + 1]}
              label={item}
            />
          ))}
        </ListItemDefault>
      </ListVertical>
    </DesktopNavigation>
  );

  const buildMobileDropdown = (open: boolean) => (
    <MobileEntriesWithPadding open={open}>
      {navigationItems.map((item: string) => (
        <UnstyledLink
          open={open}
          key={"#" + item}
          href={"#" + item}
          onClick={() => setOpen(!open)}
        >
          {item}
        </UnstyledLink>
      ))}
      <NavLinkContainer>
        {websiteUri.map((item: string, index: number) => (
          <NavigationLink
            key={item + index}
            onClickLink={() => setOpen(!open)}
            styling={Object.keys(StyleConfiguration)[(index % 3) + 1]}
            label={item}
          />
        ))}
      </NavLinkContainer>
    </MobileEntriesWithPadding>
  );

  const [open, setOpen] = React.useState(false);

  return (
    <BackgroundColor backgroundColor={backgroundColor}>
      <NavigationContainer>
        <SpaceBetween>
          <Logo
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            alt="Logo"
            src={
              "https://www.zahnarzt-stadelhofen.ch/backend/cockpit/storage/uploads/2022/02/20/62129dc17fa12dentist_logo_new_2.png"
            }
          />
          <div>
            <HeaderText uppercase={true}>Zahnarztpraxis Stadelhofen</HeaderText>
            <HeaderText>Dr.med.dent. Igor Simonovic-Wolfer</HeaderText>
          </div>
          <Spacer></Spacer>
          {buildDesktopNavigation()}
          <MobileNavigationBreadcrumb open={open} fn={setOpen} />
        </SpaceBetween>
        {buildMobileDropdown(open)}
      </NavigationContainer>
    </BackgroundColor>
  );
};
