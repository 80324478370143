import React from "react";
import {FooterList, LI} from "../styles/Styles";


interface FooterProps {
}



export const Footer: React.FC<FooterProps> = () => {

    return (
            <FooterList>
                <LI>Footer1</LI>
                <LI>Footer2</LI>
                <LI>Footer3</LI>
                <LI>Footer4</LI>
            </FooterList>
)
}
