import React from "react";
import {
  Container,
  TextListContainer,
  TextListSection,
} from "../styles/Styles";
import useFetch from "../hooks/useFetch";
import { API } from "../config/config";
import { Accordion } from "./subComponents/Accordion";

type listEntry = {
  value: {
    text: string;
    icon: string;
    description?: string;
    key: string;
  };
};

interface TextListProps {
  entries: listEntry[];
  backgroundStyle: string;
}

export interface ListIconProps {
  _id: string;
  img: {
    path: string;
  };
  name: string;
}

export const TextList: React.FC<TextListProps> = ({
  entries,
  backgroundStyle,
}) => {
  const [content] = useFetch(
    `${API.baseUrl}api/collections/get/icons?token=${API.key}`,
    { entries: [] }
  ) as any;

  let i, j;
  let columns = [];
  let chunk = entries.length / 2 + (entries.length % 2);

  for (i = 0, j = entries.length; i < j; i += chunk) {
    columns.push(entries.slice(i, i + chunk));
  }

  return (
    <Container backgroundStyle={backgroundStyle}>
      <TextListContainer>
        {columns.map((columnItem: listEntry[], index: number) => (
          <TextListSection key={index.toString() + columnItem[index].value.key}>
            {columnItem.map((item: listEntry, kIndex: number) => {
              const currentIcon = content.entries.find(
                (iconItem: ListIconProps) => {
                  return iconItem.name === item.value.icon;
                }
              );
              return (
                <Accordion
                  key={item.value.text}
                  title={item.value.text}
                  boldTitle={true}
                  icon={currentIcon}
                  content={item.value.description}
                />
              );
            })}
          </TextListSection>
        ))}
      </TextListContainer>
    </Container>
  );
};
