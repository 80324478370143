import styled from "styled-components";
import { StyleConfiguration } from "../utilities/StyleConfig";

export type styleConfig = {
  backgroundStyle: string;
  position?: string;
  overlap?: boolean;
  fullWidth?: boolean;
  padding?: string;
};
type imageConfig = {
  backgroundUrl: string;
};
type indentConfig = {
  noIndent?: boolean;
};
type overlayConfig = {
  fit?: string;
  overlayStyle?: string;
  backgroundStyle?: string;
  align?: string;
};
type linkConfig = {
  hasHover: boolean;
  isInline?: boolean;
  key: any;
};
export const H2 = styled.h2`
  font-size: 2.4rem;
  margin: 0;
  text-transform: uppercase;
`;
export const H3 = styled.h3`
  font-size: 1.8rem;
  margin: 1rem 0;
`;

export const P = styled.p<indentConfig>`
  font-size: 1.4rem;
  display: block;
  margin: ${(props) => (props.noIndent ? "0 0" : "1rem")};
`;
export const UL = styled.ul`
  list-style-type: none;
  margin: 0 1rem;
  padding: 0;
`;

export const LI = styled.li`
  margin: 10px;
`;

export const SectionTitle = styled.div<any>`
  margin: 2rem auto 0 auto;
  max-width: 1024px;
  width: 100%;
`;

export const MainContainer = styled.div`
  padding: 0 4rem;
`;
export const Container = styled.div<styleConfig>`
  display: flex;
  margin: 0 -4rem;
  padding: 2rem ${(props) => (props.fullWidth ? `0` : `4rem`)};
  justify-content: center;
  margin-top: ${(props) => (props.overlap ? `-40rem` : `0`)};
  position: ${(props) => (props.position ? props.position : `inherit`)};
  background-color: ${(props) =>
    StyleConfiguration[props.backgroundStyle].backgroundColor};
  color: ${(props) => StyleConfiguration[props.backgroundStyle].color};
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
`;
export const TitleContainer = styled.div<styleConfig>`
  display: flex;
  margin: 0 -4rem;
  padding: 2rem 4rem 0 4rem;
  padding-bottom: ${props => props.padding ? `${props.padding}rem` : 0};
  justify-content: flex-start;
  background-color: ${(props) =>
    StyleConfiguration[props.backgroundStyle].backgroundColor};
  color: ${(props) => StyleConfiguration[props.backgroundStyle].color};
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
`;
export const ContactContainer = styled.div`
  max-width: 1024px;
  position: absolute;
  z-index: 10;
  background-color: white;
  margin: 2rem;
  padding: 0 2rem 2rem 2rem;
  right: 4rem;
  top: 12rem;
  border: 1px solid #cfcfcf;
  background-color: rgb(86, 136, 101);
  color: white;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    position: relative;
    right: unset;
    top: unset;
    margin: 0;
  }
`;
export const OverlayContent = styled.div<overlayConfig>`
  position: relative;
  display: flex;
  ${(props) =>
    props.align === "row"
      ? `
        width: 100vw;
        flex-direction: row;
        justify-content: space-evenly;
        `
      : `
        flex-direction: column;
        `};
`;
export const Overlay = styled.div<overlayConfig>`
  display: flex;
  color: white;
  padding: 2rem;
  position: absolute;
  ${(props) =>
    props.backgroundStyle === "primary"
      ? `
        background-color: rgb(86, 136, 101, 0.9);`
      : props.backgroundStyle === "white"
      ? `
        background-color: rgba(255,255,255, 0.9);
    `
      : `
        background-color: rgba(0,0,0, 0.7);
    `}

  ${(props) =>
    props.overlayStyle === "top"
      ? `
        top: 8rem;
        @media screen and (max-width: 768px){
            display: none;
        }
        `
      : ""};
  ${(props) =>
    props.overlayStyle === "bottom"
      ? `
        align-self: flex-start;
        padding: 2rem 4rem;
        flex-direction: row;
        bottom: 0;
        `
      : ""};
  ${(props) =>
    props.overlayStyle === "float"
      ? `
        right: 15rem;
        top: 45rem;
        color: white;
        @media screen and (max-width: 768px){
            position: initial;
            top: unset;
            right: unset;
            bottom: unset;
            margin-top: -14rem;
            z-index: 10;
            padding: 1rem 2rem;
        }
        `
      : ``}
`;
export const OverlayContainer = styled.div<overlayConfig>`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  ${(props) =>
    props.fit
      ? `
        width: 98vw;
        height: 100vh;
        margin: -85vh -10rem 0 0;
        left: 0;
        @media screen and (max-height: 768px){
          margin: -110vh -10rem 0 0;
        }
        @media screen and (max-width: 768px){
          height: initial;
          margin: 0 -4rem 0 -4rem;
          position: initial;
        }
    `
      : ""}
`;
export const TextListContainer = styled.div`
  display: flex;
  max-width: 1024px;
  width: 100%;
  padding-bottom: 2rem;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
export const TextListSection = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
  @media screen and (max-width: 768px) {
    flex-basis: 100%;
    padding: 0;
  }
`;
export const ContactSection = styled.div`
  flex: 1 0;
  @media screen and (max-width: 768px) {
    flex-basis: 100%;
  }
`;
export const MapFull = styled.div`
  z-index: 5;
  width: 100%;
  height: 650px;
  @media screen and (max-width: 768px) {
    height: 350px;
  }
`;
export const MapContact = styled.div`
  display: flex;
  flex: 1 0;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const MapContainer = styled.div`
  width: 100%;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    max-width: 350px;
  }
`;
export const HeroOverlay = styled.div`
  position: relative;
  height: calc(100vh - 80px);
  margin: 2rem -4rem 0 -4rem;
  @media screen and (max-width: 768px) {
    height: auto;
  }
`;
export const ImageOverlayContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  height: 400px;
  width: 100%;
`;
export const ImageOverlay = styled.div<overlayConfig>`
  ${(props) => {
    return props.overlayStyle === "bottom"
      ? `
width: 100%;
max-width: 1024px;
margin: 2rem auto 0 auto;
padding: 0 4rem;
    z-index: 10;

display: flex;
align-self: flex-end;
background-color: rgba(14,80,30,0.7);
color: #ffffff;
@media screen and (max-width: 768px) {
width: auto;
margin: 2rem;
}
`
      : `    max-width: 1024px;
    position: absolute;
    z-index: 10;
background-color: rgba(14,80,30,0.7);
color: white;
    margin: 2rem;
    padding: 2rem;
    right: 4rem;
    top: 12rem;
    border: 1px solid #cfcfcf;`;
  }}
`;

export const FooterList = styled.ul`
  display: flex;
  list-style-type: none;
`;

export const SpacerMd = styled.div`
  padding-top: 1rem;
  width: 100%;
  background-color: green;
`;
export const Hero = styled.div<imageConfig>`
  background-image: ${(props) => "url(" + props.backgroundUrl + ")"};
  background-position: 30% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 768px) {
    background-size: 140%;
    height: 360px !important;
  }
`;
export const LinkItem = styled.div`
  display: flex;
  color: inherit;
  text-decoration: none;
  margin-right: 1rem;
  img {
    left: 0;
    transition: all 100ms ease-in;
    position: relative;
    max-height: 2rem;
    max-width: 2rem;
  }
  :hover {
    img {
      left: 2rem;
    }
  }
`;
export const Link = styled.a`
  color: inherit;
  text-decoration: none;
  display: block;
  transition: all 200ms ease-in;
`;
export const ConfigLink = styled.a<linkConfig>`
  color: inherit;
  font-weight: normal;
  font-size: inherit;
  text-decoration: none;
  margin-top: ${(props) => (props.isInline ? "0" : "0")};
  display: block;
  transition: all 200ms ease-in;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;
export const Logo = styled.img`
  max-height: 6rem;
  height: 6rem;
  max-width: 6rem;
  width: 6rem;
  margin-left: 13rem;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    margin-left: 4rem;
  }
  @media screen and (max-width: 768px) {
    max-height: 5rem;
    max-width: 5rem;
    margin-left: 2rem;
  }
`;
export const ItemWithIcon = styled.div`
  display: flex;
  align-items: center;
`;
export const ListIcon = styled.img`
  filter: invert(1);
  max-width: 5rem;
  max-height: 5rem;
  margin-right: 2rem;
  align-self: flex-start;
`;
