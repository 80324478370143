import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { StyleConfiguration } from "../utilities/StyleConfig";
import {device} from "../utilities/Breakpoint";

interface LanguageSwitchProps {
  /**
   * Different sites
   */
  label: string;
  styling?: string;
  onClickLink?: any;
}

type styleConfig = {
  backgroundstyle: string;
};

const CircleLink = styled(RouterLink)<styleConfig>`
  background-color: ${(props) =>
    StyleConfiguration[props.backgroundstyle].backgroundColor};
  border-radius: 10%;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  color: ${(props) => StyleConfiguration[props.backgroundstyle].color};
  padding: 0.5rem;
  font-size: 14px;
  line-height: 1.5rem;
  margin-right: 0.5rem;
  transition: all 250ms ease-in-out;
  margin-top: 1rem;
 

  &:hover {
    color: ${(props) => StyleConfiguration[props.backgroundstyle].hover};
  }
  
  @media ${device.tablet} {
  margin-top: 0;
  }
`;

const NavigationLink: React.FC<LanguageSwitchProps> = ({
  label,
  styling = "primary",
  onClickLink,
}) => (
  <CircleLink
    onClick={onClickLink ? onClickLink : () => ""}
    backgroundstyle={styling}
    to={label}
  >
    {label}
  </CircleLink>
);

export default NavigationLink;
