import React from "react";
import Slider from "react-slick";
import { API } from "../config/config";

const Carousel = ({ gallerie }: any) => {
  const resizeImageResponsive = (path: any) =>
    `${API.baseUrl}api/cockpit/image?token=${API.key}&src=${
      API.assetUrl
    }${path}&w=${window.innerWidth}&h=${
      window.innerWidth > 1024 ? "600" : "250"
    }&o=1`;
  const buildSlider = () =>
    gallerie.map((item: any) => (
      <div key={item.path}>
        <img src={resizeImageResponsive(item.path)} alt="" />
      </div>
    ));

  const settings = {
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3500,
    autoplaySpeed: 2000,
    cssEase: "linear",
    prevArrow: <></>,
    nextArrow: <></>,
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          margin: "0 auto",
        }}
      >
        <div style={{ margin: "0 -4rem" }}>
          <Slider {...settings}>{buildSlider()}</Slider>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
