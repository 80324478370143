import {HeroImage} from "./HeroImage";
import {TeamList as ImageList} from "./TeamList";
import {TextList} from "./TextList";
import {Contact} from "./Contact";
import {Footer} from "./Footer";
import {NavSectionTitle} from "./NavSectionTitle";
import {LinkList} from "./LinkList";
import {TitleOverlay} from "./TitleOverlay";
import {TextSection} from "./TextSection";
import Carousel from "./Carousel";

export const componentList: any = {
    HeroImage: HeroImage,
    imageGallery: ImageList,
    textSection: TextSection,
    linkCollection: LinkList,
    contact: Contact,
    footer: Footer,
    sectionTitle: NavSectionTitle,
    titleOverlay: TitleOverlay,
    textList: TextList,
    Carousel: Carousel,
};
