import React, { useState } from "react";
import styled from "styled-components";
import { API } from "../../config/config";
import { H3 } from "../../styles/Styles";

interface DescriptionMargin {
  margin?: any;
  show?: any;
}
interface variationConfig {
  isBold?: boolean;
  align?: string;
  isName?: boolean;
}

const AttributeListItem = styled.li``;

const AttributeList = styled.ul<DescriptionMargin>`
  padding-inline-start: 16px;
  margin: 0;
  list-style-type: none;
  padding: 0;
`;
const Subtitle = styled.h3<DescriptionMargin>`
  font-size: 1.6rem;
  font-weight: bold;
  max-height: ${(props) => (props.show ? "8rem" : "0")};
  opacity: ${(props) => (props.show ? "1" : "0")};
  margin-top: ${(props) => (props.show ? "1rem" : "0")};
  margin-bottom: ${(props) => (props.show ? "1rem" : "0")};
`;
const NavigationIcon = styled.div<any>`
  font-size: 1.8rem;
  font-weight: ${(props) => (props.bold ? "700" : "300")};
  margin-right: 0.75rem;
`;
const Paragraph = styled.div<DescriptionMargin>`
  font-size: 1.4rem;
  line-height: 1.5rem;
  max-height: ${(props) => (props.show ? "fit-content" : "0")};
  opacity: ${(props) => (props.show ? "1" : "0")};
  margin-top: ${(props) => (props.show ? "0" : "0")};
  margin-bottom: ${(props) => (props.show ? "0rem" : "0")};

  transition: max-height 100ms linear, opacity 0s linear, margin 100ms ease-out;
`;
const TextDescription = styled.div<DescriptionMargin>`
  font-size: ${(props) => (props.show ? "1.5rem" : "0")};
  margin-right: 2rem;
  margin-top: ${(props) => (props.show ? "1rem" : "0")};
  margin-bottom: ${(props) => (props.show ? "1rem" : "0")};
  max-height: ${(props) => (props.show ? "auto" : "0")};
  transition: max-height 250ms linear, opacity 200ms linear;
  opacity: ${(props) => (props.show ? "1" : "0")};
`;

const InlineItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const PaddedH3 = styled(H3)<variationConfig>`
  width: 100%;
  flex: 1 0;
  font-weight: ${(props) => (props.isBold ? "700" : "400")};
  text-align: ${(props) => (props.align ? props.align : "initial")};
  @media screen and (max-width: 768px) {
    font-size: ${(props) => (props.isName ? "1.4rem" : "1.8rem")};
  }
`;

interface CursorPointerProperty {
  active?: any;
}

const CursorPointer = styled.div<CursorPointerProperty>`
  cursor: ${(props) => (props.active ? "pointer" : "default")};
  width: 100%;
`;

export const ListIcon = styled.img`
  max-width: 5rem;
  max-height: 5rem;
  margin-right: 2rem;
  align-self: flex-start;
`;

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 20% 1fr;
`;

const IntendationLeft = styled.div`
  margin-left: 2rem;
`;

interface AccordionProperties {
  icon?: {
    img: {
      path: string;
    };
  };
  title: string;
  boldTitle?: boolean;
  subTitle?: string;
  content?: string;
  attributes?: any[];
  navIcon?: boolean;
  isName?: boolean;
}

export const Accordion = ({
  title,
  boldTitle,
  subTitle,
  content,
  attributes,
  icon,
  navIcon,
  isName,
}: AccordionProperties) => {
  const [isActive, setIsActive] = useState(false);

  const renderParagraph = (item: any) => {
    return item.value.year ? (
      <GridLayout>
        <Paragraph show={isActive}>{item.value.year}</Paragraph>
        <Paragraph show={isActive}>{item.value.text}</Paragraph>
      </GridLayout>
    ) : (
      <Paragraph show={isActive}>{item.value.text}</Paragraph>
    );
  };
  const renderAttribute = (item: any) => {
    return item.value.is_subtitle ? (
      <Subtitle key={item.value.text} show={isActive}>
        {item.value.text}
      </Subtitle>
    ) : (
      <AttributeListItem key={item.value.key}>
        {renderParagraph(item)}
      </AttributeListItem>
    );
  };

  return (
    <CursorPointer active={content || attributes}>
      <InlineItem onClick={() => setIsActive(!isActive)}>
        {icon && <ListIcon src={API.assetUrl + icon.img.path} alt={"icon"} />}
        {(content || attributes) && (boldTitle || navIcon) && (
          <NavigationIcon bold={false}>{isActive ? "-" : "+"}</NavigationIcon>
        )}
        <PaddedH3 isBold={true} isName={isName}>
          {title}
        </PaddedH3>
        {subTitle ? (
          <PaddedH3 isBold={boldTitle} align={"right"} isName={isName}>
            {subTitle}
          </PaddedH3>
        ) : (
          ""
        )}
      </InlineItem>
      <TextDescription
        show={isActive}
        margin={icon}
        onClick={() => setIsActive(!isActive)}
      >
        {content ? <IntendationLeft>{content}</IntendationLeft> : ""}
        {attributes ? (
          <AttributeList show={isActive}>
            {attributes?.map((item) => renderAttribute(item))}
          </AttributeList>
        ) : (
          <></>
        )}
      </TextDescription>
    </CursorPointer>
  );
};
