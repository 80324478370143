import React from "react";
import {
  ConfigLink,
  Overlay,
  OverlayContainer,
  OverlayContent,
} from "../styles/Styles";
import styled from "styled-components";

type textType = {
  value: {
    text_line1: string;
    text_line2: string;
    text_line3: string;
    is_bold: boolean;
    is_adress: boolean;
    is_phone: boolean;
    is_email: boolean;
  };
};

interface TitleOverlayProps {
  backgroundStyle: string;
  introText: textType[];
  contactText: textType[];
  position: string;
}

const OverlayItemText = styled.div``;
const OverlayItem = styled.div`
  font-weight: 400;
  padding: 1rem 0;
  font-size: 1.8rem;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  @media screen and (max-width: 768px) {
    padding: 0.8rem 0;
    font-size: 1.4rem;
  }
`;

const SpaceAround = styled.div`
  margin: 0.2rem 0.5rem 0 0;
  display: flex;
`;

export const TitleOverlay: React.FC<TitleOverlayProps> = ({
  introText,
  contactText,
  backgroundStyle,
}) => {
  const renderIcons = (item: textType) => {
    if (item.value.is_phone) {
      return (
        <SpaceAround>
          <span
            style={{ color: "black" }}
            className="iconify"
            data-icon="mdi:phone"
          ></span>
        </SpaceAround>
      );
    } else if (item.value.is_email) {
      return (
        <SpaceAround>
          <span
            style={{ color: "black" }}
            className="iconify"
            data-icon="mdi:email"
          ></span>
        </SpaceAround>
      );
    } else if (item.value.is_adress) {
      return (
        <SpaceAround>
          <span
            style={{ color: "black" }}
            className="iconify"
            data-icon="mdi:map-marker"
          ></span>
        </SpaceAround>
      );
    }
  };
  const mapText = (textContent: textType[]) => {
    return textContent.map((item) =>
      item.value.is_phone || item.value.is_email ? (
        <OverlayItem key={item.value.text_line1}>
          {renderIcons(item)}
          <div style={{ color: "black" }}>
            <ConfigLink
              key={item.value.text_line1}
              hasHover={true}
              isInline={true}
              href={
                (item.value.is_phone ? "tel: " : "mailto: ") +
                item.value.text_line1
              }
            >
              {item.value.text_line1}
            </ConfigLink>
          </div>
        </OverlayItem>
      ) : (
        <OverlayItem key={item.value.text_line1}>
          {renderIcons(item)}
          <OverlayItemText>
            <div style={{ color: "black" }}>{item.value.text_line1}</div>
            {item.value.text_line2 ? (
              <div style={{ color: "black" }}>{item.value.text_line2}</div>
            ) : (
              ""
            )}
            {item.value.text_line3 ? (
              <div style={{ color: "black" }}>{item.value.text_line3}</div>
            ) : (
              ""
            )}
          </OverlayItemText>
        </OverlayItem>
      )
    );
  };

  return (
    <OverlayContainer
      fit={"full"}
      backgroundStyle={backgroundStyle}
      overlayStyle={"bottom"}
    >
      {/*<Overlay backgroundStyle={"default"} overlayStyle={"bottom"}>*/}
      {/*    <OverlayContent>{mapText(introText)}</OverlayContent>*/}
      {/*</Overlay>*/}
      {/* <Overlay backgroundStyle={"primary"} overlayStyle={"top"}>
                <OverlayContent align={"row"}>{mapText(contactText)}</OverlayContent>
            </Overlay> */}
      <Overlay backgroundStyle={"white"} overlayStyle={"float"}>
        <OverlayContent>{mapText(contactText)}</OverlayContent>
      </Overlay>
    </OverlayContainer>
  );
};
